import React, { useContext, useEffect } from 'react'
import {
    Button, Container, Row, Col, Form, InputGroup, Card,
    ButtonGroup, ButtonToolbar, Dropdown, Spinner, Alert, Badge, Modal
} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import Order from '../Classes/Order'
import User from '../Classes/User'
import Queryset from '../Classes/Queryset'
import '../css/OrderDetail.css'
import {
    Building, Pencil, Trash, Truck, Journals, FileEarmarkText, FileCode, Paperclip,
    Envelope, ClipboardCheck, BoxArrowInUpRight, BoxArrowDownRight, Archive, Search,
    Upload, Globe, CashCoin, QuestionCircle, Download, ArrowReturnLeft, ArrowUpShort,
    ArrowDownShort, ExclamationTriangle
} from 'react-bootstrap-icons'
import Account from '../Classes/Account'
import LabelManager from '../Components/LabelManager'
import Customer from '../Classes/Customer'
import SearchModal from '../Components/SearchModal'
import Carrier from '../Classes/Carrier'
import Location from '../Classes/Location'
import LocationModal from '../Components/LocationModal'
import moment from 'moment'
import { getOptions } from '../Classes/Base'
import LoadingBanner from '../Components/LoadingBanner'
import Currency from '../Classes/Currency'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import EmailModal from '../Components/EmailModal'
import MessageCenter from '../Components/MessageCenter'
import { AutocompleteInput } from '../Components/AutocompleteInput'
import Vehicle from '../Classes/Vehicle'
import { OrderPreviewModal } from '../Components/OrderPreviewModal'
import { OrderLoadInformation } from '../Components/OrderLoadInformation'
import TimeMover from '../Components/TimeMover'
import { useState } from 'react'
import { dateAddDays, dateRemoveTime, dateToInputValue } from '../helpers'
import Invoice from '../Classes/Invoice'
import { ConfigContext } from '../Context'
import CompanyIdentity from '../Classes/CompanyIdentity'
import { OverlayComponent } from "../Components/OverlayComponent"
import { OpenHolidaysApi } from '../Helpers/External/OpenHolidays'
import RichTextEditor from '../Components/RichTextEditor'
import { convertToRaw, EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import { DraftJSexportOptions, DraftJSimportOptions } from '../Helpers/draftHTMLhelpers'
import ErrorBoundarySmall from '../Components/ErrorBoundarySmall'
import CarrierContact from '../Components/CarrierContact'

const SAVE_DELAY = 1000;

const blockingStateFieldExceptions = new Set([
    "state",
    "carrier_invoice",
    "carrier_missing_invoice"
])

function doesUnloadPreceedLoad(locations) {
    for (let location of locations) {
        switch (location.type) {
            case 'LOAD':
                return false
            case 'UNLOAD':
                return true
            default:
                break
        }
    }
    return false
}

function PohodaExportModal(props) {
    const { order, onInvoiceCreated } = props
    const [show, setShow] = useState(false);
    const [saving, setSaving] = useState(false);
    const [dueDate, setDueDate] = useState(dateAddDays(dateRemoveTime(new Date()), order.customer?.maturity || 20));
    const [customerOrder, setCustomerOrder] = useState(order.customer_order || "")
    const config = useContext(ConfigContext)

    useEffect(() => {
        setCustomerOrder(order.customer_order)
    }, [order, show])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const invoiceConditions = order.customer && (order.carrier || (!order.invoice && order.carrier_missing_invoice));
    const isAccounting = Account.current().hasPerm("accounting");

    async function createInvoice() {
        if (order.margin <= 0 && !window.confirm("Chcete vytvořit fakturu k objednávce bez marže?")) return;
        setSaving(true)
        const invoice = new Invoice({
            id: 0,
            customer_order: customerOrder,
            customer: order.customer.id,
            orders: [order],
            due_date: dateToInputValue(dueDate)
        })
        try {
            const response = await invoice.save();
            if (invoice.__status !== 201) throw Error(`Unexpected HTTP status ${invoice.__status}: ${response}`)
            onInvoiceCreated && onInvoiceCreated(invoice)
            handleClose()
        }
        catch (e) {
            MessageCenter.addMessage({
                title: "Nepodařilo se uložit fakturu!",
                text: "Zkuste to znovu, případně kontaktujte podporu"
            })
            console.error(e)
        }
        finally {
            setSaving(false)
        }
    }

    async function pohodaDownload() {
        await (new Invoice(order.invoice).to_pohoda())
        window.location.reload()
    }

    return <>
        <DropdownItem onClick={() => handleShow()}><FileCode /> Pohoda XML</DropdownItem>

        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Pohoda XML export</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-5">
                <Row>
                    <Col>
                        <Card className='h-100' bg={order.carrier ? "" : "danger"}>
                            <Card.Body bg="danger">
                                <Card.Title>Přijatá objednávka</Card.Title>
                                <Card.Text as="div">
                                    {order.carrier ?
                                        <>Objednávka vystavená dopravcem <br /><em>{Carrier.icon_as_component()} {new Carrier(order.carrier).label()}</em></> :
                                        "Nelze stáhnout objednávku od dopravce, protože dopravce není přiřazený."}
                                </Card.Text>
                            </Card.Body>
                            {order.carrier && <Button onClick={() => { order.pohodaXMLDownload("received") }} variant="primary"><Download /> Stáhnout</Button>}
                        </Card>
                    </Col>
                    <Col>
                        <Card className='h-100' bg={order.customer ? "" : "danger"}>
                            <Card.Body bg="danger">
                                <Card.Title>Přijatá objednávka</Card.Title>
                                <Card.Text as="div">
                                    {order.customer ?
                                        <>Objednávka vystavená pro zákazníka <br />{Customer.icon_as_component()} <em>{new Customer(order.customer).label()}</em></> :
                                        "Nelze stáhnout objednávku pro zákazníka, protože zákazník není přiřazený"}
                                </Card.Text>
                            </Card.Body>
                            {order.customer && <Button onClick={() => { order.pohodaXMLDownload("issued") }} variant="primary"><Download /> Stáhnout</Button>}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {config.accounting ? <Card className='h-100 mt-3' bg={invoiceConditions || !isAccounting ? "" : "danger"}>
                            {isAccounting ? <Card.Body>
                                <Card.Title>Vystavit fakturu</Card.Title>
                                <Card.Text as="div">
                                    {invoiceConditions && order.margin <= 0 && <Alert variant='danger'>
                                        <strong>Bez marže!</strong> Objednávka je bez marže. Zkontrolujte prosím uvedené ceny.
                                    </Alert>}
                                    {order.invoice ?
                                        "Objednávka už má vystavenou fakturu. Stahování faktur probíhá na stránce faktury." :
                                        invoiceConditions ? <>
                                            <Alert variant='primary' className='p-1 text-center'>
                                                <small>Pro fakturaci více objednávek použijte funkci "Účetnictví ⇒ Objednávky"</small>
                                            </Alert>
                                            <Form.Group controlId='due_date'>
                                                <Form.Label>Datum splatnosti</Form.Label>
                                                <Form.Control type="date" value={dateToInputValue(dueDate)} onChange={e => setDueDate(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group controlId='due_date'>
                                                <Form.Label>Číslo objednávky u zákazníka</Form.Label>
                                                <Form.Control value={customerOrder} onChange={e => setCustomerOrder(e.target.value)} placeholder="použít logisticiq id" />
                                                <Form.Text>
                                                    Označení objednávky u zákazníka. Použije se na vygenerovaných dokumentech.
                                                </Form.Text>
                                            </Form.Group>
                                        </> : <div>
                                            Není možné vystavit fakturu, nejsou splněné podmínky!
                                            <ul>
                                                {!order.customer && <li>Není přiřazen zákazník</li>}
                                                {!order.carrier ? <li>Není přiřazen dopravce </li> : (!order.carrier_order || !order.carrier_missing_invoice) && <li>Je přiřazen dopravce, ale není vyřešena jeho objednávka. Pokud chybí označte odpovídající pole</li>}
                                            </ul>
                                        </div>
                                    }
                                </Card.Text>
                            </Card.Body> : <Card.Body><Alert variant='secondary'>
                                Uživatelé s odpovídajícím oprávněním mohou také vystavit fakturu přímo v systému LogisticIQ. Možnost může zapnout správce systému.
                            </Alert></Card.Body>}
                            {order.invoice ?
                                <ButtonGroup>
                                    <Button onClick={pohodaDownload}><Download /> Stáhnout</Button>
                                    <Button variant="outline-primary" href={`/invoice/${order.invoice.id}`}><Search /> Zobrazit</Button>
                                </ButtonGroup> : invoiceConditions && isAccounting && <Button onClick={createInvoice}>Vytvořit</Button>}
                        </Card> : <Alert variant='secondary' className='mt-3'>
                            <em>Systém logistic podporuje fakturaci. Integrace s pohodou umožňuje sledovat stav a prodlevy objednávek zpracovaných v pohodě. </em>
                        </Alert>}
                    </Col>
                </Row>
                {saving && <div className="fixed-bottom">
                    <Container>
                        <Row>
                            <Col sm={12} md={8} lg={4} className="ml-auto bg-light d-flex p-2 mb-1 border rounded align-items-center justify-content-center">
                                <Spinner animation="border" variant="info" /> <h3 className="ml-3 mb-0">Stahování</h3>
                            </Col>
                        </Row>
                    </Container>
                </div>}
            </Modal.Body>
        </Modal>
    </>
}


function formattedAddress(place) {
    return place && (place.street || place.house_number || place.town || place.postal_code || place.country) ? <>
        {place.street && <>{place.street} {place.house_number} <br /></>}
        {(place.town || place.postal_code) && <>{place.town} {place.postal_code} <br /></>}
        {place.country && <>{place.country} <br /></>}
    </> : <strong>Adresa není vyplněná!</strong>
}

function formattedLocationContact(location) {
    return location.name || location.phone || location.email || location.code ? <>
        {location.name && <>{location.name} <br /></>}
        {location.phone && <>{location.phone} <br /></>}
        {location.email && <>{location.email} <br /></>}
        {location.code && <strong>CODE: {location.code} <br /></strong>}
    </> : <strong>Kontakt není vyplněný!</strong>
}

function i18n_date(date) {
    return new Intl.DateTimeFormat("cs-CZ", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    }).format(new Date(date))
}

class OrderDetail extends React.Component {
    email_modal = React.createRef()

    state = {
        queryset: new Queryset(Order),
        new_location: false,
        edit_location: undefined,
        updating: false,
        currencies: new Queryset(Currency),
        users: new Queryset(User),
        companyIdentities: new Queryset(CompanyIdentity),
        locationsReordering: false,
        hints: {
            locationsNotSortedByTime: undefined
        }
    }

    onUploadClicked = () => {
        const input = document.createElement("input")
        input.type = "file"
        input.click()
        input.onchange = async e => {
            await this.state.queryset.object.uploadAttachment(e.target.files[0])
            this.forceUpdate()
        }
    }

    async onInvoiceCreated(invoice) {
        if (!this.state.queryset.object) {
            console.warn("No object to append the invoice to");
            window.location.reload();
            return;
        }
        invoice?.to_pohoda && invoice.to_pohoda();
        await this.state.queryset.one(this.state.queryset.object.id)
        this.forceUpdate();
    }


    /**
     * This function is called when the component is first rendered.
     * It retrieves the options for the order, fetches the currencies, users, and company identities,
     * loads the data, sets the options, and checks if the order is in the trash.
     * If the order is in the trash, a message is added to the message center.
     * If the user has the permission to permanently delete the order, a message is added to the message center.
     * Finally, it checks if there are any blocking labels on the order.
     *
     * @return {Promise<void>} A promise that resolves when the function completes
     */
    async componentDidMount() {
        const options = getOptions(Order)
        const currencies = this.state.currencies.all()
        const users = this.state.users.all()
        const identities = this.state.companyIdentities.all()
        await Promise.all([currencies, users, identities, this.load_data()])
        this.setState({ options: await options })

        if (!this.state.queryset.object) return;
        if (this.state.queryset.object.state === "TRASH") {
            MessageCenter.addMessage({
                title: "Objednávka je v koši",
                text: (
                    <>
                        Tato objednávka je v koši připravená k odstranění,
                        jestli máte zájem ji zachovat, změňte její stav.
                        {Account.current().level > 0 && (
                            <>
                                <br />
                                Máte oprávnění objednávku trvale odstranit
                            </>
                        )}
                    </>
                )
            })
        }
        this.updateHolidays()
        this.checkBlockingLabels()
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.error) {
            return;
        }
        if (!this.state.queryset.object || prevProps.match.params.id !== this.props.match.params.id) {
            await this.load_data()
            this.forceUpdate()
        }
    }

    async reconcoleHints() {
        let locationsNotSortedByTime = false
        let latest = new Date(0)
        for (let location of this.state.queryset.object.locations) {
            let location_time = new Date(location.time)
            if (location_time > latest) {
                latest = location_time
            } else {
                locationsNotSortedByTime = true
            }
        }

        this.setState({
            hints: {
                locationsNotSortedByTime
            }
        })
    }

    async load_data() {
        const res = await this.state.queryset.one(this.props.match.params.id)
        if (!this.state.queryset.object && !res?.id) {
            this.setState({ error: "Nepodařilo se načíst objednávku!" })
            return;
        }
        this.reconcoleHints()
    }

    async locationSaved(location) {
        //Process the changed location locally
        this.state.new_location ?
            this.state.queryset.object.addLocation(location) :
            this.state.queryset.object.editLocation(location)
        this.updateHolidays()
        this.setState({ edit_location: undefined, new_location: false })
    }

    async delete() {
        if (await this.state.queryset.object.delete()) {
            this.props.history.push("/order")
        }
        else {
            MessageCenter.addMessage("Objednávku se nepodařilo smazat, zkuste to prosím znovu, nebo kontaktujte podporu.")
        }
    }

    async deleteLocation(location) {
        if (!window.confirm(`Opravdu chcete smazat zastávku:\n${moment(location.time).format("DD.MM.YYYY HH:mm")}: ${location.place ? location.place.name : "Neznámé místo"}`)) {
            return;
        }
        location = new Location(location)
        await location.delete() && this.state.queryset.object.removeLocation(location)
        this.forceUpdate()
    }

    async updateHolidays() {
        try {
            let countries = this.state.queryset.object?.locations.map(element => element.place?.country).filter(country => country);
            let dates = [...this.state.queryset.object?.locations.map(element => { return Date.parse(element.end_time) }),
            ...this.state.queryset.object?.locations.map(element => { return Date.parse(element.time) })];

            const holidays = await OpenHolidaysApi.getHolidays(
                countries,
                "cs",
                new Date(Math.min(...dates)),
                new Date(Math.max(...dates))
            )
            this.setState({ holidays: holidays.toHolidaysHelper() })
        }
        catch (err) {
            MessageCenter.addMessage({
                "title": "Nepodařilo se načíst informace o svátcích",
                "text": "Selhalo načtení informací o svátcích. Zkuste to později, případně kontaktujte podporu"
            })
            console.error(err);
        }

    }

    updateBlockingState() {
        return this.state.queryset.object.state !== "NEW"
    }

    checkBlockingLabels() {
        const blocking_labels = this.state.queryset.object.labels.filter(i => i.blocking)
        if (blocking_labels.length) {
            const labels = blocking_labels.map(i => i.name).join(", ")
            MessageCenter.addMessage({
                title: "Objednávka obsahuje blokující štítky",
                text: `Štítky (${labels}) musíte odstranit, jinak nepůjde objednávku editovat.`
            })
        }

    }

    updateValue(name, value) {
        if (name === "weight" && value === "") value = null;
        if (name === "state" && value === "FINISHED" &&
            (!this.state.queryset.object.customer_order || !this.state.queryset.object.margin)) {
            if (!window.confirm("Opravdu chcete nastavit stav na dokončeno, když není nastaveno číslo objednávky u zákazníka, nebo marže je nulová?")) return
        }
        // TODO: Nefunkcni. 
        if (!blockingStateFieldExceptions.has(name) && this.checkBlockingLabels()) {
            return;
        }
        if (this.updateBlockingState() && name !== "state") {
            MessageCenter.addMessage({
                title: "Blokující stav",
                text: `Stav objednávky  "${this.state.options.actions.POST.state.choices.filter(i => { return i.value === this.state.queryset.object.state })[0].display_name}" neumožňuje změny. Změňte stav objednávky!`
            })
            return;
        }
        const queryset = this.state.queryset
        queryset.object[name] = value
        if (name === "carrier" && value) {
            queryset.object.due_days = value.default_due_days
            queryset.object.carrier_invoice = value.carrier_invoice
            queryset.object.carrier_invoice_update = value.carrier_invoice_update
        }
        //TODO FIX THIS UGLY SHIT
        if (value && typeof (value) === "object" && !Array.isArray(value)) {
            value = value.id
        }
        clearTimeout(this.state.updating)
        this.setState({
            "queryset": queryset,
            "updating": setTimeout(async () => {
                await this.state.queryset.object.save({ [name]: value })
                this.setState({ updating: false })
            }, SAVE_DELAY)
        })
        //TODO FIX THIS UGLY SHIT
        value && name === "carrier" && this.state.queryset.object.save({ due_days: queryset.object.due_days })
    }

    removeRelated(relation) {
        if (!this.state.queryset.object[relation]) return;
        const trans = { customer: "zákazníka", carrier: "dopravce", vehicle: "vozidlo" }
        if (window.confirm(`Opravdu chcete odebrat této objednávce ${trans[relation]} - ${this.state.queryset.object[relation].name || this.state.queryset.object[relation].spz}`)) {
            this.updateValue(relation, null)
        }
    }

    async copy() {
        const copy = await this.state.queryset.object.copy()
        if (copy) {
            this.props.history.push("/order/" + copy.id)
        }
    }

    async submitOrderEmail(data) {
        try {
            if (!await this.state.queryset.object.send_email(data)) {
                throw Error("Server returned an error")
            }
            else {
                MessageCenter.addMessage({
                    title: 'Email odeslán!'
                });
            }
        } catch {
            MessageCenter.addMessage({
                title: 'Chyba při odesílání zprávy!',
                text: 'Bohužel se někde něco rozbilo, zkuste to znovu a případně kontaktujte podporu!',
                detail: 'Data použitá k uložení: \n' + JSON.stringify(data)
            })
        }
    }

    async deleteAttachment(e, f) {
        e.preventDefault()
        await this.state.queryset.object.deleteAttachment(f)
        this.forceUpdate()
    }

    async doTimeshift(milliseconds, overwrites) {
        if (!await this.state.queryset.object.timeshift(milliseconds / 1000, overwrites)) {
            MessageCenter.addMessage({
                "title": "Posun se nepovedl",
                "message": "Zkuste to znovu, nebo kontaktujte podporu."
            })
        }
        else {
            window.location.reload()
        }
    }

    async moveLocation(location, position) {
        if (position < 0 || position >= this.state.queryset.object.locations.length) return;
        if (this.updateBlockingState()) {
            MessageCenter.addMessage({
                title: "Blokující stav",
                text: `Stav objednávky  "${this.state.options.actions.POST.state.choices.filter(i => { return i.value === this.state.queryset.object.state })[0].display_name}" neumožňuje změny. Změňte stav objednávky!`
            })
            return;
        }
        this.setState({
            locationsReordering: true
        })
        await Location.prototype.reorder.call(location, position)
        await this.load_data()
        this.setState({
            locationsReordering: false
        })
    }

    carrierContactSelected(contact) {
        this.updateValue("carrier_contact", contact)
    }

    render() {
        if (this.state.error) {
            return <Container>
                <Alert variant='danger'>
                    <strong>Nastala chyba</strong><br />
                    <div>{this.state.error}</div>
                </Alert>
            </Container>
        }
        if (!this.state.queryset.object || !this.state.options || !this.state.currencies.objects || !this.state.users.objects) {
            return <Container className="mt-3"><LoadingBanner /></Container>
        }
        else {
            const toolbar_content = <>
                <OrderPreviewModal className="mr-1" order={this.state.queryset.object} />
                <ButtonGroup className="ml-auto">
                    <Dropdown className="mr-1">
                        <Dropdown.Toggle variant="outline-success">
                            <Paperclip /> Přílohy
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.state.queryset.object.attachments && (this.state.queryset.object.attachments.length > 0) && <>
                                {this.state.queryset.object.attachments.map(i => {
                                    return <Dropdown.Item key={i.id} target="_blank" href={i.file}>
                                        <div className="d-flex justify-content-between">
                                            {i.file.split("/").pop()}
                                            {(Account.current().level > 0 || Account.current().hasPerm('delete_attachments') || Account.current().id === this.state.queryset.object?.user?.id) &&
                                                <Button onClick={e => { this.deleteAttachment(e, i) }} className="ml-1" variant="outline-danger" size="sm"><Trash /></Button>}
                                        </div>
                                    </Dropdown.Item>
                                })}
                                <Dropdown.Divider />
                            </>}
                            <Dropdown.Item onClick={() => this.onUploadClicked()}><Upload /> Nahrát</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="mr-1">
                        <Dropdown.Toggle id="dropdown-basic" variant="primary">
                            <Journals /> Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item target="_blank" onClick={() => this.state.queryset.object.carrierPdfDownload()}><FileEarmarkText /> PDF pro dopravce</Dropdown.Item>
                            <PohodaExportModal order={this.state.queryset.object} onInvoiceCreated={invoice => this.onInvoiceCreated(invoice)} />
                            <Dropdown.Item onClick={() => { this.email_modal.current.setShow(true) }}><Envelope /> Odeslat e-mailem</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" variant="secondary">
                            <Archive /> Operace
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item target="_blank" onClick={() => { this.copy() }}><ClipboardCheck /> Kopírovat</Dropdown.Item>
                            <TimeMover
                                firstLocationTime={this.state.queryset.object.first_location_time}
                                locations={this.state.queryset.object.locations}
                                onSelected={(milliseconds, overwrites) => this.doTimeshift(milliseconds, overwrites)}
                            />
                            {this.state.queryset.object.state !== 'TRASH' && <Dropdown.Item target="_blank" className="text-danger"
                                title="Přesunout do koše" onClick={() => { this.updateValue("state", "TRASH") }}>
                                <Trash /> Přesunout do koše
                            </Dropdown.Item>}
                            {this.state.queryset.object.state === 'TRASH' &&
                                Account.current().level > 0 &&
                                <DropdownItem className="text-danger" onClick={() => { this.delete() }}> <Trash /> Smazat natrvalo</DropdownItem>}
                        </Dropdown.Menu>
                    </Dropdown>
                </ButtonGroup>
            </>

            return <>
                <EmailModal order={this.state.queryset.object} submitCallback={(data) => { this.submitOrderEmail(data) }} ref={this.email_modal} />
                <LocationModal
                    order={{ id: this.props.match.params.id }}
                    show={this.state.new_location || !!this.state.edit_location}
                    location={this.state.edit_location}
                    saved={location => this.locationSaved(location)}
                    cancel={() => this.setState({ edit_location: undefined, new_location: false })}
                    key={this.state.edit_location ? this.state.edit_location.id : 0}
                />
                <Container className="order-container" fluid={true}>
                    <ButtonToolbar className="align-items-right mb-2 d-flex d-lg-none">
                        {toolbar_content}
                    </ButtonToolbar>
                    <Row className="flex-grow-1">
                        <Col lg="2" md="4" sm="6" className="h-100 bg-light flex-column flex-grow-1">
                            <h3 className='mb-0 text-center'># {this.state.queryset.object && this.state.queryset.object.code}</h3>
                            {this.state.queryset.object.invoice && (Account.current().hasPerm('accounting') ? <Badge pill variant='danger' className='d-block'>
                                <a href={`/invoice/${this.state.queryset.object.invoice.id}`}>
                                    <span style={{ fontSize: "1.5em", color: "white" }}>Faktura #{this.state.queryset.object.invoice.id}</span>
                                </a>
                            </Badge> : <Badge pill variant="primary">
                                <span style={{ fontSize: "1.5em", color: "white" }}>Faktura #{this.state.queryset.object.invoice.id}</span>
                            </Badge>)}
                            <LabelManager
                                labels={this.state.queryset.object.labels}
                                updateLabels={labels => { this.updateValue("labels", labels) }}
                            />
                            <Form.Group className='mt-2'>
                                <Form.Label>Cena pro zákazníka</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={this.state.queryset.object.price_customer} onChange={e => this.updateValue('price_customer', e.target.value)} />
                                    <InputGroup.Append>
                                        <Form.Control as="select" disabled={!!this.state.queryset.object.invoice} custom value={this.state.queryset.object.customer_currency.code} onChange={e => this.updateValue('customer_currency', e.target.value)}>
                                            {this.state.currencies.objects.map((currency, key) => {
                                                return <option key={key} value={currency.code}>{currency.label}</option>
                                            })}
                                        </Form.Control>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.state.queryset.object.vehicle ? "Náklady na dopravu" : "Cena pro dopravce"}</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={this.state.queryset.object.price_carrier} onChange={e => this.updateValue('price_carrier', e.target.value)} />
                                    <InputGroup.Append>
                                        <Form.Control as="select" disabled={!!this.state.queryset.object.invoice} custom value={this.state.queryset.object.carrier_currency.code} onChange={e => this.updateValue('carrier_currency', e.target.value)}>
                                            {this.state.currencies.objects.map((currency, key) => {
                                                return <option key={key} value={currency.code}>{currency.label}</option>
                                            })}
                                        </Form.Control>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Příplatek zákazníka</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={this.state.queryset.object.surcharge_customer} onChange={e => this.updateValue('surcharge_customer', e.target.value)} />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {this.state.queryset.object.customer_currency.label}
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <Row>
                                <Col xs="6">Marže</Col>
                                <Col xs="6" className={`text-right ${this.state.queryset.object.margin > 0 ? "text-success" : "text-danger"}`}>
                                    {this.state.queryset.object.margin.toFixed(2)} {this.state.queryset.object.customer_currency.label}
                                </Col>
                            </Row>
                            <hr />
                            <Form.Group>
                                <Form.Label>Hodnota nákladu</Form.Label>
                                <InputGroup>
                                    <Form.Control type="number" value={this.state.queryset.object.load_price} onChange={e => this.updateValue('load_price', e.target.value)} />
                                    <InputGroup.Append>
                                        <Form.Control as="select" custom value={this.state.queryset.object.load_currency.code} onChange={e => this.updateValue('load_currency', e.target.value)}>
                                            {this.state.currencies.objects.map((currency, key) => {
                                                return <option key={key} value={currency.code}>{currency.label}</option>
                                            })}
                                        </Form.Control>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                            <hr />
                            <Form.Group>
                                <Form.Label>Objednávku vytvořil</Form.Label>
                                <Form.Control readOnly={true} value={this.state.queryset.object && new User(this.state.queryset.object.created_by).label()} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Přiřazený uživatel</Form.Label>
                                <Form.Control as="select" custom value={this.state.queryset.object.user && this.state.queryset.object.user.id} onChange={e => this.updateValue('user', e.target.value)}>
                                    {this.state.users.objects.map((user, key) => {
                                        return <option key={key} value={user.id}>{user.label()}</option>
                                    })}
                                    <option value="">{"<Žádný>"}</option>
                                </Form.Control>
                            </Form.Group>
                            <hr />
                            <Form.Group>
                                <Form.Label>Jazyk</Form.Label>
                                <Form.Control as="select" custom value={this.state.queryset.object.language} onChange={e => this.updateValue('language', e.target.value)}>
                                    {this.state.options.actions.POST.language.choices.map((lang, key) => {
                                        return <option key={key} value={lang.value}>{lang.display_name}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Stav objednávky</Form.Label>
                                <Form.Control as="select" custom value={this.state.queryset.object.state} onChange={e => this.updateValue('state', e.target.value)}>
                                    {this.state.options.actions.POST.state.choices.map((state, key) => {
                                        return <option key={key} value={state.value}>{state.display_name}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <hr />
                            <div className='my-1 text-muted'>Vytvořeno:<br />{new Date(this.state.queryset.object.created_at).toLocaleString()}</div>
                        </Col>
                        <Col lg="10" md="8" sm="6">
                            <ButtonToolbar className="align-items-right mb-2 d-none d-lg-flex">
                                {toolbar_content}
                            </ButtonToolbar>
                            {this.state.queryset.object.invoice && <Alert variant="danger">
                                <div>
                                    <strong>Objednávka už je {Account.current().hasPerm('accounting') ?
                                        <a href={"/invoice/" + this.state.queryset.object.invoice.id}>fakturována</a>
                                        : <>fakturována pod číslem {this.state.queryset.object.invoice.id}</>}
                                    </strong>. Upravit je možné pouze interní poznámky. Změnami v objednávce může dojít k problémům v účetnictví.
                                </div>
                            </Alert>}
                            <Row>
                                <Col md="5">
                                    {this.state.companyIdentities.count > 0 && <Card className='mb-2'>
                                        <Card.Body className='d-flex justify-content-between align-items-center p-1 px-3'>
                                            <div><strong>Identita společnosti: </strong>
                                                {this.state.queryset.object.company_identity?.name} {this.state.queryset.object.company_identity?.default && <em>(výchozí)</em>}
                                            </div>
                                            <ButtonGroup>
                                                <SearchModal
                                                    klass={CompanyIdentity}
                                                    closeCallback={company_identity => this.updateValue('company_identity', company_identity)}
                                                />
                                                {this.state.queryset.object.company_identity?.id > 0 && <Button
                                                    variant="secondary"
                                                    label="Použít výchozí identitu"
                                                    onClick={() => this.updateValue('company_identity', null)}
                                                >
                                                    <ArrowReturnLeft />
                                                </Button>}
                                            </ButtonGroup>
                                        </Card.Body>
                                    </Card>}
                                    {/* Customer */}
                                    <Card className="mb-2">
                                        <Card.Header className="d-flex justify-content-between py-1">
                                            <span><Building /> Zákazník</span>
                                            {this.state.queryset.object.customer && <ButtonGroup size='sm'>
                                                <Button size="sm" variant="outline-primary"
                                                    href={"/customer/" + this.state.queryset.object.customer.id}><Search /></Button>
                                                <Button size="sm" variant="outline-danger" onClick={() => this.removeRelated('customer')}><Trash /></Button>
                                            </ButtonGroup>}
                                        </Card.Header>
                                        <Card.Body className='pb-0'>
                                            {this.state.queryset.object.customer?.blacklist && <Alert style={{ fontSize: "16px", padding: "3px 15px", margin: "0px" }} variant="danger">
                                                <strong>Zákazník je na černé listině</strong>
                                            </Alert>}
                                            <Card.Title> {this.state.queryset.object && this.state.queryset.object.customer && this.state.queryset.object.customer.name} </Card.Title>
                                            <Card.Text as="div">
                                                {!this.state.queryset.object.customer ?
                                                    <div style={{ marginTop: "-15px", marginBottom: "15px" }}><SearchModal label="Vybrat zákazníka" variant="outline-primary" klass={Customer} fields={['ID', 'name']} pkSearch={true} createNew
                                                        closeCallback={entry => (!entry.blacklist || window.confirm("Zákazník je na černé listině! Chcete ho přidat?")) && this.updateValue("customer", entry)} />
                                                    </div> :
                                                    <>
                                                        <Form.Group>
                                                            <Form.Label>Číslo objednávky u zákazníka</Form.Label>
                                                            <Form.Control value={this.state.queryset.object.customer_order} onChange={e => this.updateValue('customer_order', e.target.value)} />
                                                        </Form.Group>
                                                    </>}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    {/* Carrier */}
                                    <Card className="mb-2">
                                        <Card.Header className="d-flex justify-content-between py-1">
                                            <span><Truck /> Doprava</span>
                                            {this.state.queryset.object.carrier && <> <em>přes dopravce</em><ButtonGroup>
                                                <Button size="sm" variant="outline-primary"
                                                    href={"/carrier/" + this.state.queryset.object.carrier.id}><Search /></Button>
                                                <Button size="sm" variant="outline-danger" onClick={() => this.removeRelated('carrier')}><Trash /></Button>
                                            </ButtonGroup></>}
                                            {this.state.queryset.object.vehicle && <> Vozidlo <ButtonGroup>
                                                <Button size="sm" variant="outline-primary"
                                                    href={"/vehicle/" + this.state.queryset.object.vehicle.id}><Search /></Button>
                                                <Button size="sm" variant="outline-danger" onClick={() => this.removeRelated('vehicle')}><Trash /></Button>
                                            </ButtonGroup></>}
                                        </Card.Header>
                                        <Card.Body className="pt-2 pb-2">
                                            <Card.Text as="div" className='d-flex justify-content-between'>
                                                <div>
                                                    <Card.Title>
                                                        <div className='d-flex justify-content-between flex-grow-1 align-items-center'>
                                                            <div>{this.state.queryset.object && this.state.queryset.object.carrier && this.state.queryset.object.carrier.name}</div>
                                                            {this.state.queryset.object.carrier?.blacklist && <Alert style={{ fontSize: "16px", padding: "3px 15px", margin: "0px" }} variant="danger">
                                                                <strong>Dopravce je na černé listině</strong>
                                                            </Alert>}
                                                        </div>
                                                    </Card.Title>
                                                    {
                                                        this.state.queryset.object && (
                                                            this.state.queryset.object.carrier ?
                                                                (this.state.queryset.object.carrier.street || this.state.queryset.object.carrier.house_number ||
                                                                    this.state.queryset.object.carrier.town || this.state.queryset.object.carrier.postal_code ||
                                                                    this.state.queryset.object.carrier.country) && <div>
                                                                    {this.state.queryset.object.carrier.street}&nbsp;{this.state.queryset.object.carrier.house_number},{' '}
                                                                    {this.state.queryset.object.carrier.town} {this.state.queryset.object.carrier.postal_code}, {this.state.queryset.object.carrier.country}
                                                                </div>
                                                                : this.state.queryset.object.vehicle ?
                                                                    <div>
                                                                        Vozidlo - {this.state.queryset.object.vehicle.spz}<br />
                                                                        <Globe /> {this.state.queryset.object.vehicle.carrier ? this.state.queryset.object.vehicle.carrier.name : <em>Vozidlo nemá dopravce</em>}
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex justify-content-between">
                                                                        <SearchModal label="Vybrat dopravce" variant="outline-primary" klass={Carrier} fields={['ID', 'name', 'ico', 'blacklist']} pkSearch={true} createNew={["name", "ico", "raal"]}
                                                                            closeCallback={entry => { if (!entry.blacklist || window.confirm(`Dopravce ${entry.name} je na černé listině, opravdu ho chcete použít?`)) this.updateValue("carrier", entry) }} />
                                                                        <SearchModal label="Vybrat vozidlo" variant="outline-success" klass={Vehicle} fields={['id', 'spz', 'type']} pkSearch={true} createNew
                                                                            closeCallback={entry => { this.updateValue("vehicle", entry) }} />
                                                                    </div>
                                                        )
                                                    }
                                                    {
                                                        this.state.queryset.object.carrier && <div className='border-top mt-2 pt-2'>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>Dní splatnosti objednávky</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="number" min="0"
                                                                    value={this.state.queryset.object.due_days}
                                                                    onChange={e => this.updateValue('due_days', e.target.value)}
                                                                />
                                                            </InputGroup>
                                                            <InputGroup>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>Faktura dopravce</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control
                                                                    value={this.state.queryset.object.carrier_invoice}
                                                                    disabled={this.state.queryset.object.carrier_missing_invoice}
                                                                    onChange={e => this.updateValue("carrier_invoice", e.target.value)}
                                                                />
                                                            </InputGroup>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div className='text-muted'>
                                                                    {this.state.queryset.object.carrier_invoice_update && <small>
                                                                        Datum zadání faktury:
                                                                        {new Date(this.state.queryset.object.carrier_invoice_update).toLocaleDateString()}
                                                                    </small>}
                                                                </div>
                                                                <Form.Check type="checkbox" label="Bez faktury"
                                                                    disabled={this.state.queryset.object.carrier_invoice}
                                                                    checked={this.state.queryset.object.carrier_missing_invoice}
                                                                    onChange={e => this.updateValue("carrier_missing_invoice", e.target.checked)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    <CarrierContact
                                                        onCarrierContactSelected={contact => this.carrierContactSelected(contact)}
                                                        carrier={this.state.queryset.object.vehicle?.carrier || this.state.queryset.object.carrier}
                                                        orderContact={this.state.queryset.object.carrier_contact}
                                                    />
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    {/* Vozidlo a posádka */}
                                    <Card className="mb-2">
                                        <Card.Header className="py-1">
                                            Vozidlo a posádka
                                        </Card.Header>
                                        <Card.Body className="py-1">
                                            <Row className="mb-1">
                                                <Form.Label className="col-form-label col-sm-6">Požadovaný typ vozu:</Form.Label>
                                                <InputGroup className="col-sm-6">
                                                    <AutocompleteInput
                                                        loadAutocomplete={async (value) => {
                                                            return (await Order.autocomplete("vehicle_type", value)).values.map(i => {
                                                                return i.vehicle_type
                                                            })
                                                        }}
                                                        value={this.state.queryset.object.vehicle_type}
                                                        itemSelected={item => { this.updateValue('vehicle_type', item) }}
                                                    />
                                                </InputGroup>
                                            </Row>
                                            <Row className="mb-1">
                                                <Form.Label className="col-form-label col-sm-6">Registrační značka:</Form.Label>
                                                <InputGroup className="col-sm-6">
                                                    <Form.Control value={this.state.queryset.object.license_plate} onChange={e => this.updateValue('license_plate', e.target.value)} />
                                                </InputGroup>
                                            </Row>
                                            <Row className="mb-1">
                                                <Form.Label className="col-form-label col-sm-6">Jméno řidiče:</Form.Label>
                                                <InputGroup className="col-sm-6">
                                                    <Form.Control value={this.state.queryset.object.driver_name} onChange={e => this.updateValue('driver_name', e.target.value)} />
                                                </InputGroup>
                                            </Row>
                                            <Row>
                                                <Form.Label className="col-form-label col-sm-6">Telefon na řidiče:</Form.Label>
                                                <InputGroup className="col-sm-6">
                                                    <Form.Control value={this.state.queryset.object.driver_phone} onChange={e => this.updateValue('driver_phone', e.target.value)} />
                                                </InputGroup>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Body>
                                            <OrderLoadInformation order={this.state.queryset.object} save={data => this.updateValue('requirements', data)} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="7">
                                    {
                                        doesUnloadPreceedLoad(this.state.queryset.object.locations) && <Alert variant='warning'>
                                            Pozor! V objednávce je vykládka je před nakládkou.
                                        </Alert>
                                    }
                                    {this.state.locationsReordering ? <LoadingBanner label="Přeskupování zastávek" /> :
                                        <div>
                                            {this.state.hints.locationsNotSortedByTime && <Alert variant='warning'>
                                                Zastávky nejsou řazené chronologicky. Podpora pro vlastní pořadí zastávek je experimentální, kontrolujte výstupy a případné problémy hlašte na podporu.
                                            </Alert>}
                                            {this.state.queryset.object?.locations.map((location, key) => {
                                                const position = this.state.queryset.object.locations.length - 1 - key
                                                // Pokud ma zeme misto a mame nactene svatky, tak je muzeme ukazovat
                                                const holidaysInfo = (location?.place?.country && this.state.holidays) ?
                                                    this.state.holidays.hasHolidays(location.place.country, location.time, location.end_time) : []
                                                return (<React.Fragment key={key}><div className="border rounded py-2 px-1 mb-2 bg-light">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <div className="text-center">
                                                                {location.type === "LOAD" ? <BoxArrowInUpRight />
                                                                    : location.type === "UNLOAD" ? <BoxArrowDownRight />
                                                                        : location.type === "TOLL" ? <CashCoin />
                                                                            : <QuestionCircle />} {Location.prototype.prettyName.call(location)}
                                                            </div>
                                                            <div className='text-center'>
                                                                {i18n_date(location.time)}
                                                                {location.end_time && location.time !== location.end_time && <> - {i18n_date(location.end_time)}</>}
                                                                {location.fixed_window && <span style={{ color: "red" }}>
                                                                    {" "}
                                                                    <ExclamationTriangle title='Fixní časové okno zastávky' />
                                                                    &nbsp;Fixní okno
                                                                </span>
                                                                }
                                                                <br></br>
                                                                {holidaysInfo.length !== 0 && <>
                                                                    <OverlayComponent
                                                                        text={<Button variant="link" className="text-dark p-0">
                                                                            <span style={{ color: "orange", fontWeight: "bold" }}>
                                                                                <ExclamationTriangle title='Otevírací doba se může lišit' />
                                                                                &nbsp; Otevírací doba se může lišit &nbsp;
                                                                            </span>
                                                                        </Button>}
                                                                        helpTopic="order_detail_holidays"
                                                                        minWidth="500px"
                                                                        placement="left"
                                                                    >
                                                                        <p>
                                                                            Následující svátky se slaví v zemi dané zastávky a mohou ovlivnit otevírací dobu
                                                                        </p>
                                                                        <ul>
                                                                            <ErrorBoundarySmall>
                                                                                {!!holidaysInfo ? holidaysInfo.map((holiday, index) => <li key={index}>
                                                                                    {(holiday?.startDate && moment(holiday.startDate).format("DD") !== moment(holiday.endDate).format("DD")) &&
                                                                                        moment(holiday.startDate).format("DD.MM.")} {moment(holiday.endDate).format("DD.MM.YYYY")} - {holiday?.name[0]?.text}
                                                                                </li>
                                                                                ) : "Informace o státních svátcích nejsou k dispozici. Zkuste obnovi stránku."}
                                                                            </ErrorBoundarySmall>
                                                                        </ul>
                                                                    </OverlayComponent>
                                                                </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <ButtonGroup size="sm" className="locationButtonGroup">
                                                            <Button size="sm" variant="success" onClick={() => this.setState({ edit_location: location })}><Pencil /></Button>
                                                            <Button size="sm" variant="danger" onClick={() => this.deleteLocation(location)}><Trash /></Button>
                                                            <div className='d-flex flex-column justify-content-between rounded'>
                                                                <div className={position + 1 < this.state.queryset.object.locations.length ? "reorder" : "reorder disabled"} title="Nahoru" onClick={() => this.moveLocation(location, position + 1)}>
                                                                    <ArrowUpShort />
                                                                </div>
                                                                <div className={position !== 0 ? "reorder" : "reorder disabled"} title="Dolů" onClick={() => this.moveLocation(location, position - 1)} disabled={position === 0}>
                                                                    <ArrowDownShort />
                                                                </div>
                                                            </div>
                                                        </ButtonGroup>
                                                    </div>
                                                    <Row>
                                                        <Col>
                                                            <Card>
                                                                <Card.Header className="py-1">Adresa</Card.Header>
                                                                <Card.Body className="py-1">
                                                                    {formattedAddress(location.place)}
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                        <Col>
                                                            <Card>
                                                                <Card.Header className="py-1">Kontakt</Card.Header>
                                                                <Card.Body className="py-1">
                                                                    {formattedLocationContact(location)}
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    {location.load && <div><strong>Náklad</strong> {location.load} </div>}
                                                    {location.notes && <div><strong>Poznámky</strong> {location.notes} </div>}
                                                </div>
                                                </ React.Fragment>)
                                            })}
                                        </div>
                                    }
                                    {this.state.queryset.object && !this.state.queryset.object.locations.length ?
                                        <div className="no-entry">
                                            <strong>Nejsou vyplněné žádné lokality</strong>
                                            <Button onClick={() => this.setState({ new_location: true })}>Přidat zastávku</Button>
                                        </div> :
                                        <Button onClick={() => this.setState({ new_location: true })} block>Přidat zastávku</Button>

                                    }
                                    {!!this.state.queryset.object.locations.length && <Form.Group>
                                        <Row className="mt-2">
                                            <Form.Label className="col-form-label col-sm-3">Vzdálenost</Form.Label>
                                            <InputGroup className="col-sm-4">
                                                <Form.Control value={this.state.queryset.object.distance || ""} onChange={e => this.updateValue('distance', e.target.value)} />
                                            </InputGroup>
                                            <Col className="text-muted d-flex align-items-center">
                                                {
                                                    this.state.queryset.object.distance &&
                                                    <>
                                                        Cena za jednotku {
                                                            Math.round((this.state.queryset.object.price_customer
                                                                + this.state.queryset.object.surcharge_customer)
                                                                / this.state.queryset.object.distance * 100) / 100
                                                        } {this.state.queryset.object.customer_currency.label}
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </Form.Group>}
                                    <hr />
                                    <Row className='mt-1'>
                                        <Col sm="6">
                                            <Form.Group className='d-flex align-items-center mb-1' controlId="exampleForm.ControlTextarea1">
                                                <Form.Label className='text-nowrap mr-1 mb-0'>Počet palet</Form.Label>
                                                <Form.Control value={this.state.queryset.object.palletes} onChange={e => this.updateValue('palletes', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="6">
                                            <Form.Group className='d-flex align-items-center mb-1' controlId="exampleForm.ControlTextarea1">
                                                <Form.Label className='text-nowrap mr-1 mb-0'>Váha (v kg)</Form.Label>
                                                <Form.Control placeholder="nezadáno" value={this.state.queryset.object.weight || ""} onChange={e => this.updateValue('weight', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Label as="h3">Poznámky</Form.Label>
                                                {/* <Form.Control as="textarea" rows={3} value={this.state.queryset.object.notes} onChange={e => this.updateValue('notes', e.target.value)} /> */}
                                                <RichTextEditor
                                                    editorState={EditorState.createWithContent(stateFromHTML(this.state.queryset.object.notes, DraftJSimportOptions))}
                                                    features={{
                                                        small_buttons: true,
                                                        load_changes: false,
                                                        formats: false,
                                                    }}
                                                    setEditorState={(e) => { 
                                                        if (convertToRaw(stateFromHTML(this.state.queryset.object.notes, DraftJSimportOptions)) !== convertToRaw(e.getCurrentContent())) { 
                                                            // console.log(stateToHTML(e.getCurrentContent(), DraftJSexportOptions));
                                                            this.updateValue('notes', stateToHTML(e.getCurrentContent(), DraftJSexportOptions)) } 
                                                        }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="6">
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Label as="h3">Interní poznámky</Form.Label>
                                                {/* <Form.Control as="div" value={this.state.queryset.object.internal_notes} onChange={e => this.updateValue('internal_notes', e.target.value)}> */}
                                                <RichTextEditor
                                                    editorState={EditorState.createWithContent(stateFromHTML(this.state.queryset.object.internal_notes, DraftJSimportOptions))}
                                                    features={{
                                                        small_buttons: true,
                                                        load_changes: false,
                                                        formats: false,
                                                    }}
                                                    setEditorState={(e) => { 
                                                        if (convertToRaw(stateFromHTML(this.state.queryset.object.internal_notes, DraftJSimportOptions)) !== convertToRaw(e.getCurrentContent())) { 
                                                            // console.log(stateToHTML(e.getCurrentContent(), DraftJSexportOptions));
                                                            this.updateValue('internal_notes', stateToHTML(e.getCurrentContent(), DraftJSexportOptions)) } 
                                                        }}
                                                />
                                                {/* </Form.Control> */}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                {this.state.updating && <div className="fixed-bottom">
                    <Container>
                        <Row>
                            <Col sm={12} md={8} lg={4} className="ml-auto bg-light d-flex p-2 mb-1 border rounded align-items-center justify-content-center">
                                <Spinner animation="border" variant="info" /> <h3 className="ml-3 mb-0">Ukládání změn</h3>
                            </Col>
                        </Row>
                    </Container>
                </div>}
            </>
        }
    }
}

export default withRouter(OrderDetail)
