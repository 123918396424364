import React from 'react'
import { Modal, Button, Row, Col, ListGroup, ListGroupItem, Form, Alert, Spinner } from 'react-bootstrap';
import LoadingBanner from '../Components/LoadingBanner';
import fetchProxy from '../Helpers/fetchProxy';
import settings from '../Settings';
import MessageCenter from './MessageCenter';
import UserForm from './UserForm'

const NEW_USER = {
    id: 0,
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    is_superuser: false,
}

class UserModal extends React.Component {
    constructor(props) {
        super(props)
        this.user_form = React.createRef()
        if (props.closeCallback !== null) {
            this.closeCallback = props.closeCallback
        }
        this.state = {
            show: false,
            user: NEW_USER,
            permissions: undefined,
            activePermissionsMap: {},
            permissionsUpdating: {}
        }
    }

    setShow = (show) => {
        this.setState({ show })
    }

    reset = () => {
        this.setState({ user: NEW_USER, activePermissionsMap: {}, permissionsUpdating: {}, permissionChanged: undefined })
    }

    closeCallback = () => {
        // Default callback can be implemented here. 
    }

    handleShow = () => this.setShow(true)
    handleClose = () => {
        this.setShow(false)
        this.reset()
    }

    saveChanges = () => {
        const user = this.user_form.current.getUser()
        this.setState({ user })
        this.user_form.current.setState({ user })
        if (!user.username) {
            MessageCenter.addMessage({ title: "Vyplňte uživatelské jméno!" })
            return
        }
        if (this.closeCallback !== null) {
            this.closeCallback()
        }
        this.handleClose()
    }

    componentDidMount() {
        fetchProxy(settings.BASE_API_URL + "list_permissions").then(async (response) => {
            if (response.status != 200) {
                MessageCenter.addMessage({
                    "title": "Selhalo načítání oprávnění",
                    "text": "Bohužel se nepodařilo načíst oprávnění. Zkuste to znovu, případně nahlašte chybu"
                })
            }
            else {
                const data = await response.json();
                this.setState({
                    permissions: data.permissions
                })
            }
        })
    }

    loadUser(user) {
        this.setState({
            user,
            show: true,
            activePermissionsMap: Object.fromEntries(user.user_permissions.map(i => [i.codename, i]))
        })
    }

    async permissionChange(key, value) {
        this.setState({
            permissionChanged: true,
            permissionsUpdating: {
                ...this.state.permissionsUpdating,
                [key]: true
            }
        })
        const request = await fetchProxy(settings.BASE_API_URL + `user/${this.state.user.id}/change_permission/`, {
            method: "POST",
            body: JSON.stringify({
                name: key,
                value
            })
        })
        if (request.status === 200) {
            this.setState({
                activePermissionsMap: {
                    ...this.state.activePermissionsMap,
                    [key]: value
                }
            })
        } else {
            MessageCenter.addMessage({ "title": "Nepodařilo se změnit nastavení", "text": "Zkuste to znovu, případně nahlašte chybu" })
        }
        this.setState({
            permissionsUpdating: {
                ...this.state.permissionsUpdating,
                [key]: false
            }
        })
    }

    render() {
        const is_new_user = this.state.user.id === 0
        let title = (this.state === null || this.state.user.id === 0) ? "Nový uživatel" : "Úprava uživatele"
        return (
            <>
                <Button variant="success" onClick={this.handleShow}>Přidat uživatele</Button>
                <Modal size={is_new_user ? "md" : "xl"} show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={is_new_user ? "12" : "6"}>
                                <UserForm ref={this.user_form} user={this.state.user} new={this.state.user.id === 0} permissionChanged={() => this.setState({
                                    permissionChanged: true
                                })}/>
                            </Col>
                            {!is_new_user && <Col>
                                <h2>Rozšířená oprávnění</h2>
                                {this.state.permissions === undefined
                                    ? <LoadingBanner />
                                    : <ListGroup>
                                        {this.state.permissions.map((i, index) => <ListGroupItem key={index}>
                                            <Form.Check
                                                type="checkbox"
                                                id={i.value}
                                                label={i.label}
                                                onChange={e => this.permissionChange(i.value, e.target.checked)}
                                                checked={this.state.activePermissionsMap[i.value] || false} />
                                        </ListGroupItem>)}
                                    </ListGroup>}
                            </Col>}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.permissionsUpdating && Object.values(this.state.permissionsUpdating).some(i => i) && <Spinner animation='border' sr-only="Načítám" size='sm' />}
                        {this.state.permissionChanged && <Alert variant="warning" className='py-2'>Po změnách oprávnění se musí uživatel znovu přihlásit!</Alert>}
                        <Button variant="secondary" onClick={this.handleClose}>
                            Zavřít
                        </Button>
                        <Button variant="primary" onClick={this.saveChanges}>
                            Uložit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>)
    }
}

export default UserModal;