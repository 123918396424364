import React, { useContext, useState } from 'react';
import { Navbar, Nav, Dropdown, Button, Container, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Paperclip, Journals, Archive, Trash, Upload, FileEarmarkText, Envelope, LockFill, Clipboard, ClipboardCheck, LayoutSidebar, Save } from 'react-bootstrap-icons';
import { OrderPreviewModal } from '../OrderPreviewModal';
import PohodaExportModal from './PohodaExportModal';
import TimeMover from '../TimeMover';
import Account from '../../Classes/Account';
import Order from '../../Classes/Order';
import { OrderEditorContext } from './context';
import MessageCenter from '../MessageCenter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EmailModal from '../EmailModal';
import { CopyModal } from './CopyModal';

function OrderTopBar(props) {
    const { order, readOnly, changes, saveOrder, saving, reloadOrder, quickSaveOrder } = useContext(OrderEditorContext);
    const [open, setOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const history = useHistory();
    const email_modal = React.createRef();

    const hasChanges = typeof changes === "object" && changes && Object.keys(changes).length > 0

    async function deleteAttachment(e, attachment) {
        e.preventDefault();
        await Order.prototype.deleteAttachment.call(order, attachment);
        reloadOrder();
    }

    function onUploadClicked() {
        const input = document.createElement("input")
        input.type = "file"
        input.click()
        input.onchange = async e => {
            await Order.prototype.uploadAttachment.call(order, e.target.files[0])
            reloadOrder()
        }
    }
    
    async function doTimeshift(milliseconds, overwrites) {
        if (!await Order.prototype.timeshift.call(order, milliseconds / 1000, overwrites)) {
            MessageCenter.addMessage({
                "title": "Posun se nepovedl",
                "message": "Zkuste to znovu, nebo kontaktujte podporu."
            })
        }
        else {
            reloadOrder();
        }
    }

    async function deleteOrder() {
        if (!window.confirm("Opravdu chcete smazat tuto objednávku?")) {
            return;
        }
        try {
            await Order.prototype.delete.call(order);
            history.push("/order");
        }
        catch (error) {
            MessageCenter.addMessage("error", "Nepodařilo se smazat objednávku: " + error.message);
        }
    }

    function onInvoiceCreated(_invoice) {
        reloadOrder();
    }

    function saveChanges() {
        saveOrder();
    }

    function handleCopy() {
        navigator.clipboard.writeText(order.code).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    }

    function renderChanges(changes) {
        return Object.entries(changes).map(([key, value]) => {
            if (typeof value === 'object') {
                if (value === null) {
                    return <div key={key}>{key}: "Smazáno"</div>;
                }
                return <div key={key}>{key}: {value?.id || "Nelze rozpoznat"}</div>;
            }
            return <div key={key}>{key}: {value}</div>
        })
        
    }

    async function sendEmail(data) {
        try {
            if (!await order.send_email(data)) {
                throw Error("Nepodařilo se odeslat email. Server vrátil chybu");
            }
            else {
                MessageCenter.addMessage({
                    title: 'Email odeslán!'
                });
            }
        } catch {
            MessageCenter.addMessage({
                title: 'Chyba při odesílání zprávy!',
                text: 'Bohužel se někde něco rozbilo, zkuste to znovu a případně kontaktujte podporu!',
                detail: 'Data použitá k uložení: \n' + JSON.stringify(data)
            })
        }
    }

    return (
        <Navbar bg="light" expand="lg" className="mb-3">
            <EmailModal ref={email_modal} order={order} submitCallback={sendEmail} />
            <Container style={{ maxWidth: '1400px' }}>
                <Navbar.Text>
                    {props.sidebarCollapsed && <Button variant='link' onClick={() => {props.toggleSidebar && props.toggleSidebar()}}>
                        <LayoutSidebar />    
                    </Button>}
                </Navbar.Text>
                <Navbar.Brand className='mr-1' onClick={handleCopy} style={{ cursor: 'pointer' }}>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-copy">{copied ? <><ClipboardCheck /> Zkopírováno </> : <><Clipboard /> Kopírovat kód </> }</Tooltip>}
                    >
                        <span>
                            #{order.code}
                        </span>
                    </OverlayTrigger>
                </Navbar.Brand>
                <Navbar.Text className="mr-5">
                {readOnly && (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-readonly">Objednávka je uzamčená</Tooltip>}
                    >
                        <Navbar.Text style={{ marginTop: '-5px' }}>
                            <LockFill className="text-danger ml-1" />
                        </Navbar.Text>
                    </OverlayTrigger>
                )}
                </Navbar.Text>
                <Navbar.Toggle aria-controls="order-topbar" onClick={() => setOpen(!open)} />
                <Navbar.Collapse id="order-topbar">
                    <Nav>
                        <OrderPreviewModal className="mr-1" order={order} />
                    </Nav>
                    <Nav className="ml-auto">
                        <Dropdown className="mr-1 mt-1 w-100">
                            <Dropdown.Toggle variant="outline-success" className="w-100">
                                <Paperclip /> Přílohy
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {order.attachments && order.attachments.length > 0 && (
                                    <>
                                        {order.attachments.map(i => (
                                            <Dropdown.Item key={i.id} target="_blank" href={i.file}>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    {i.file.split("/").pop()}
                                                    {(Account.current().level > 0 || Account.current().hasPerm('delete_attachments') || Account.current().id === order?.user?.id) && (
                                                        <Button onClick={e => { deleteAttachment(e, i) }} className="ml-1" variant="outline-danger" size="sm"><Trash /></Button>
                                                    )}
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        <Dropdown.Divider />
                                    </>
                                )}
                                <Dropdown.Item onClick={onUploadClicked}><Upload /> Nahrát</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="mr-1 mt-1 w-100">
                            <Dropdown.Toggle id="dropdown-basic" variant="outline-primary" className="w-100">
                                <Journals /> Export
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item target="_blank" onClick={() => order.carrierPdfDownload()}><FileEarmarkText /> PDF pro dopravce</Dropdown.Item>
                                <PohodaExportModal order={order} onInvoiceCreated={onInvoiceCreated} />
                                <Dropdown.Item onClick={() => { email_modal?.current?.setShow(true) }}><Envelope /> Odeslat e-mailem</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className="mr-1 mt-1 w-100">
                            <Dropdown.Toggle id="dropdown-basic" variant="outline-secondary" className="w-100">
                                <Archive /> Operace
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <CopyModal />
                                <TimeMover
                                    firstLocationTime={order.first_location_time}
                                    locations={order.locations}
                                    onSelected={(milliseconds, overwrites) => doTimeshift(milliseconds, overwrites)}
                                />
                                {order.state !== 'TRASH' && (
                                    <Dropdown.Item target="_blank" className="text-danger" title="Přesunout do koše" onClick={() => { quickSaveOrder({"state": "TRASH"}) }}>
                                        <Trash /> Přesunout do koše
                                    </Dropdown.Item>
                                )}
                                {order.state === 'TRASH' && Account.current().level > 0 && (
                                    <Dropdown.Item className="text-danger" onClick={deleteOrder}><Trash /> Smazat natrvalo</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Nav.Item>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        {!hasChanges ? "Zde objeví seznam případných změn" : renderChanges(changes) }
                                    </Tooltip>
                                }
                            >
                                {!saving ? <Button className='mt-1 d-flex align-items-center' variant='primary' disabled={!hasChanges} onClick={() => { saveChanges() }}>
                                    <Save className='mr-1' /> Uložit
                                </Button> : <Button className='mt-1 d-flex align-items-center' variant='primary' disabled={true}>
                                    <Spinner size="sm" animation='border' className='mr-1' /> Ukládám
                                </Button>}
                            </OverlayTrigger>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default OrderTopBar;
