import { useState } from "react"
import { Form } from "react-bootstrap"

export function UserSelector(props) {
    const { user, users, userChanged, readOnly } = props
    const [selectedUserId, setSelectedUserId] = useState(user ? user.id : "", 0)

    function updateValues(value) {
        setSelectedUserId(value)
        userChanged(value || null)
    }
    return <Form.Control as="select" custom disabled={readOnly}
        value={selectedUserId}
        /* If the value is "" make it null for no user assigned */
        onChange={e => updateValues(e.target.value)}>
        {users.map((user, key) => {
            return <option key={key} value={user.id}>{user.label()}</option>
        })}
        <option value="">{"<Žádný>"}</option>
    </Form.Control>
}