import React from 'react'
import { Spinner, ButtonToolbar, ButtonGroup, Form, Button, Jumbotron, Table, Pagination, Row, Col} from 'react-bootstrap';
import MessageCenter from '../Components/MessageCenter'
import { getOptions } from "../Classes/Base"
import Queryset from '../Classes/Queryset'
import ModalNew from '../Components/ModalNew';
import { ArrowLeftShort, ArrowRight, ArrowRightShort, ChevronDoubleLeft, ChevronDoubleRight, Trash, X } from 'react-bootstrap-icons';

const SEARCH_COOLDOWN = 400
const PAGES_VISIBLE = 5

class ModelSelector extends React.Component {
    constructor(props) {
        super(props)
        this.redirect = props.redirect || true
        this.page = 1
        this.klass = props.klass
        this.state = {
            queryset : new Queryset(this.klass),
            search: "",
            filter: {page: 1},
            options: undefined,
            limit: undefined,
            pk_match: null,
        }
        this.modal_ref = React.createRef()
        this.columns = this.props.columns || this.klass.columns || this.defaultColumns()
        this.show_controls = props.show_controls !== undefined ? props.show_controls : true;
        this.show_search = props.show_search !== undefined ? props.show_search : true;
        this.show_thead = props.show_thead !== undefined ? props.show_search : true;
        this.use_pagination = props.use_pagination !== undefined ? props.use_pagination : true;
        this.search_running = false
    }

    defaultColumns() {
        return [
            {
                name: "id",
                label: "#",
            },
            {
                name: "name",
                label: "Jméno"
            }
        ]
    }

    async loadData(update) {
        delete this.state.filter.search
        if (this.state.search !== "") {
            this.state.filter.search = this.state.search
        }
        const filter = {
            ...this.state.filter,
            ...this.props.filter || {}
        }
        await this.state.queryset.filter(filter)
        this.state.limit || this.setState({limit: this.state.queryset.count})
        if (update !== false) {
            this.forceUpdate()
        }
    }

    async deleteEntry(value) {
        if (await value.delete()) {
            this.state.queryset.remove(value)
            this.forceUpdate()
        }
    }

    async setDefaultFilters() {
        const filter_with = {}
        
        this.filters && this.filters.forEach(filter => {
            filter_with[filter.name] = filter.default
        })
        filter_with.page = 1
        filter_with.limit = 10
        await this.setState({filter: filter_with})
    }

    async loadCount() {
        const filter = {...this.state.filter, ...this.props.filter}
        delete filter.page
        delete filter.limit
        delete filter.search
        if (this.state.search !== "") {
            filter.search = this.state.search
        }
        const count = await this.klass.serverCount(filter)
        this.setState({"server_count" : count})
    }

    async componentDidMount() {
        await this.setDefaultFilters()
        if (this.props.use_pagination === undefined ? true : this.props.use_pagination) {
            this.loadCount()
        }
        
        const options = await getOptions(this.klass)
        this.setState({options})
        await this.loadData(false)
    }

    async new() {
        const name = this.modal_ref.current.state.name
        let object = new this.klass({"id": 0, name})
        if (await object.save()) {
            this.state.queryset.push(object);
            this.forceUpdate()
            this.modal_ref.current.handleClose()
            this.redirect && this.itemDetail(object)
        } else {
            MessageCenter.addMessage({
                title: 'Chyba při ukládání!',
                text:'Bohužel se nepovedlo uložit nový objekt! Zkuste to prosím znovu, nebo kontaktujte podporu',
                detail: 'Data použitá k uložení: \n' + JSON.stringify(object)
            })
        }
        return object
    }

    setPage(page) {
        this.updateFilter("page", page)
        this.loadData()
    }

    build_pagination() {
        const items = []
        const pages = Math.ceil(this.state.server_count / this.state.limit) + 1
        // Let's start the pagination in the middle
        let start = this.state.filter.page - Math.floor(PAGES_VISIBLE/2)
        if (start + PAGES_VISIBLE > pages) {
            start-=(start+PAGES_VISIBLE - pages)
        }
        // Make sure we start at 1
        start = start >= 1 ? start : 1
        let end = Math.min(start + PAGES_VISIBLE, pages)

        for (let i=start; i<end;++i) {
            items.push(<Pagination.Item key={i} active={i === this.state.filter.page} onClick={() => {this.setPage(i)}} >
                    {i}
            </Pagination.Item>)
        }

        return <Pagination className='justify-content-center'>
            <Pagination.Item onClick={() => {this.setPage(this.state.filter.page -1)}} disabled={this.state.filter.page === 1}><ArrowLeftShort /></Pagination.Item>
            <Pagination.Item onClick={() => {this.setPage(1)}} disabled={this.state.filter.page === 1}><ChevronDoubleLeft /></Pagination.Item>
            {items}
            <Pagination.Item onClick={() => {this.setPage(pages - 1)}} disabled={this.state.filter.page === pages - 1}><ChevronDoubleRight /></Pagination.Item>
            <Pagination.Item onClick={() => {this.setPage(this.state.filter.page +1)}} disabled={this.state.filter.page === pages - 1}><ArrowRightShort /></Pagination.Item>
        </Pagination>
    }

    updateFilter(name, value) {
        const filter = this.state.filter
        if (value !== undefined) {
            filter[name] = value
        }
        else {
            delete filter[name]
        }
        this.setState({filter})
        this.loadData(true)
        if (name !== "page") {
            this.loadCount()
        }
    }

    itemDetail(item) {
        if (this.props.history) {
            !this.props.noDetailView && this.props.history.push(this.props.history.location.pathname + "/" + encodeURIComponent(item.id))
        }
        if (this.props.select === 1) {
            this.props.itemSelected && this.props.itemSelected(item)
        }
        
    }

    renderFilter(filter) {
        const state = this.state.filter[filter.name] ? "checked" : ""
        if (filter.type === "checkbox") {
            return <Form.Group key={`filter-${filter.name}`} controlId={`filter-${filter.name}`} className="mb-0 px-2 pt-3">
                <Form.Check type="checkbox" label={`${filter.label || "Jenom moje objednávky"}`} checked={state} onChange={() => {
                    this.updateFilter(filter.name, (state ? undefined: filter.value ))
                }}/>
            </Form.Group>
        }
        else if (filter.type === "select") {
            return <Form.Group key={`filter-${filter.name}`} controlId={`filter-${filter.name}`} className="pt-2">
                <Form.Control as="select" custom onChange={ev => this.updateFilter(filter.name, ev.target.value)}>
                    {(filter.values || filter.getValues()).map(item => {
                            return <option key={item.value} value={item.value}>{item.display_name}</option> 
                    })}
                </Form.Control>
            </Form.Group>
        }
        console.warn("Unknown type of filter!", filter)
        return null
    }

    async pkSearch(search) {
        if (!search) {
            this.setState({pk_match: undefined})
            return;
        }
        const queryset = new Queryset(this.klass)
        await queryset.one(search);
        this.setState({
            pk_match: queryset.status === 200 ? queryset.object : null
        })
    }

    async searchChanged(search) {
        search !== undefined && this.setState({search})
        if (this.search_running) {
            clearTimeout(this.search_running)
        }
        this.search_running = setTimeout(() => {
            this.loadData()
            this.loadCount()
            this.props.pkSearch && this.pkSearch(search)
            this.search_running = false
        }, SEARCH_COOLDOWN)
    }

    render() {
        return (
            <div className="itemview">
                { (this.jumbotronTitle || this.props.jumbotronTitle) && (this.jumbotronSubtitle || this.props.jumbotronSubtitle) ? <Jumbotron className="mb-0">
                    <h1>{this.jumbotronTitle || this.props.jumbotronTitle}</h1>
                    <p>
                        {this.jumbotronSubtitle || this.props.jumbotronSubtitle}
                    </p>
                </Jumbotron> : ""}
                <ButtonToolbar className="align-items-center mb-0">
                    <ButtonGroup>
                        {
                            /* If there is a button for new difined, use it. Otherwise, if modal new label is defined you can display a generic one */
                            this.new_button || (!this.props.modalNewLabel && !this.modalNewLabel ? "" :  <ModalNew placeholder={this.modalNewPlaceholder || this.props.modalNewPlaceholder}
                            label={this.modalNewLabel || this.props.modalNewLabel}
                            title={this.modalNewTitle || this.props.modalNewTitle}
                            showText={this.modalNewLabel || this.props.modalNewLabel}
                            ref={this.modal_ref}
                            closeCallback={() => {this.new()}} 
                            />)
                        }
                    </ButtonGroup>
                    { this.filters && <ButtonGroup className="ml-2">
                        {this.filters.map(item => {return this.renderFilter(item)})}
                    </ButtonGroup>}
                    <Form className="ml-auto btn-group input-group">
                        {this.show_search && <>
                        <input type="text" value={this.state.search} className="form-control" placeholder="Hledat" onChange={(event) => {this.searchChanged(event.target.value)}} />
                        <Button variant="secondary" className="flex-grow-0">
                            {!this.search_running ? /*<FontAwesomeIcon icon={faSearch} />*/"S" : 
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="sr-only">Saving...</span>
                                </Spinner>}
                        </Button>
                        </>}
                    </Form>
                </ButtonToolbar>
                {this.state.pk_match && <Button className='ml-auto d-block' onClick={() => this.itemDetail(this.state.pk_match)}>
                    #{this.state.pk_match.id} {this.state.pk_match.label && this.state.pk_match.label()}
                </Button>}
                <Table stripped={this.props.stripped === undefined ? "true" : this.props.stripped.toString() } bordered hover size={this.props.table_size || "lg"}>
                    {this.showThead ? <thead>
                        <tr>
                            {this.columns.map(item => {
                                return <th key={item.name}>{item.label}</th>
                            })}
                            {this.show_controls && <th>Ovládání</th>}
                        </tr>
                    </thead> : null}
                    <tbody>
                        {this.props.emptySelector && <tr key="__missing__" style={{cursor:"pointer"}} onClick={() => {this.itemDetail(null)}}>
                            <td className='text-danger'><X /></td>
                            <td colSpan={this.columns.length-1}>{this.props.emptySelector}</td>
                        </tr>}
                        {this.state.queryset.objects.map(value => {
                            const options = this.state.options
                            const row = <tr key={value.id} style={{cursor:"pointer"}} onClick={() => {this.itemDetail(value)}}>
                                {this.columns.map(item => {
                                    /*display = display || (""+value[item.name]).toLowerCase().search(this.state.search.toLowerCase()) !== -1*/
                                    return <td key={item.name + "-" + value.id}>
                                        {!item.transform ? value[item.name] : item.transform(value[item.name], options, value)}
                                    </td>
                                })}
                                {this.show_controls && <td>
                                <Button variant="outline-danger" onClick={(event) => {event.stopPropagation(); this.deleteEntry(value)}}>
                                    <Trash />
                                </Button>
                                </td>}
                            </tr>
                            return row
                        })}
                        {this.state.queryset.count < 1 && <tr>
                            <td className="text-center" colSpan={this.columns.length + this.show_controls}><strong>{this.state.queryset.count === 0 ? "Bez výsledků" : "Na výsledky čekáme"}</strong></td>
                        </tr>}
                    </tbody>
                </Table>
                { this.props.use_pagination && <>
                {this.state.queryset.count === -1 || !this.state.server_count ? null : 
                        this.state.queryset.count === this.state.server_count ? null : this.build_pagination()}
                {this.state.queryset.count !== -1 &&<Row>
                    <Col sm="6">
                     {!!this.state.server_count && this.state.queryset.count !== this.state.server_count && 
                        <p>{this.state.limit ? <em>Záznamů na stránku: {this.state.limit}</em> : ""}</p>}
                    </Col>
                    <Col sm="6" className="text-right">
                        <p>
                            {!!this.state.server_count &&
                                <em>
                                    Nalezeno záznamů: {" "}
                                    {this.state.server_count}
                                    {this.state.limit > this.state.server_count && <>(stránek: {Math.ceil(this.state.server_count / this.state.limit)})</>}
                                </em>}
                        </p>
                    </Col>
                </Row>}
                </>}
            </div>
        );
    }
}

export default ModelSelector;