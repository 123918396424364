import { Badge, Button, Col, Form, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import Account from "../../Classes/Account";
import LabelManager from "../LabelManager";
import User from "../../Classes/User";
import { useContext } from "react";
import { OrderEditorContext } from "./context";
import { LayoutSidebar } from "react-bootstrap-icons";
import { UserSelector } from "./UserSelector";

const MARGIN_AFFECTIONG_CHANGES = new Set(['price_customer', 'price_carrier', 'surcharge_customer', 'customer_currency', 'carrier_currency']);

function OrderEditorSidebar(props) {

    const { order, changes, setChanges, getCurrentValue, currencies } = useContext(OrderEditorContext);

    const { readOnly } = useContext(OrderEditorContext);

    const languages = props.options.actions.POST.language.choices;
    const state_choices = props.options.actions.POST.state.choices;
    const marginComputed = !!Object.keys(changes).filter(i => MARGIN_AFFECTIONG_CHANGES.has(i)).length;

    function updateValues(values) {
        setChanges({ ...changes, ...values });
    }

    function getCurrencyByCode(code) {
        return currencies.find(currency => currency.code === code);
    }

    
    function marginValue() {
        if (marginComputed) {
            try {
                const price_carrier_in_customer_currency = getCurrentValue('price_carrier') / getCurrentValue('carrier_currency').exchange_rate * getCurrentValue('customer_currency').exchange_rate
                console.log(price_carrier_in_customer_currency, getCurrentValue('price_customer') + getCurrentValue('surcharge_customer'))
                return parseFloat(getCurrentValue('price_customer')) + parseFloat(getCurrentValue('surcharge_customer'))  - price_carrier_in_customer_currency;
            }
            catch (e) {
                return null;
            }
        }
        return order.margin
    }
    const margin = marginValue();

    return (
        <div className="order-editor-sidebar">
            {/* Invoices (do we want to explain this?) */}
            {order.invoice && <Badge pill variant='danger' className='d-block'>
                {Account.current().hasPerm('accounting') ? <a href={`/invoice/${order.invoice.id}`}>
                    <span style={{ fontSize: "1.5em", color: "white" }}>Faktura #{order.invoice.id}</span>
                </a> : <span style={{ fontSize: "1.5em", color: "white" }}>Faktura #{order.invoice.id}</span>}
            </Badge>}
            {/* Labels handling */}
            <LabelManager
                labels={getCurrentValue('labels')}
                updateLabels={labels => { updateValues( {labels}) }} />
            {/* Prices */}
            <Form.Group className='mt-2 mb-0'>
                <Form.Label className="mb-0">Cena pro zákazníka</Form.Label>
                <InputGroup>
                    <Form.Control type="number" disabled={readOnly} value={getCurrentValue('price_customer')} onChange={e => updateValues({ price_customer: e.target.value })} />
                    <InputGroup.Append>
                        <Form.Control as="select" disabled={readOnly} custom value={getCurrentValue('customer_currency')?.code} onChange={e => updateValues({ customer_currency: getCurrencyByCode(e.target.value) })}>
                            {props.currencies.map((currency, key) => {
                                return <option key={key} value={currency.code}>{currency.label}</option>
                            })}
                        </Form.Control>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
            <Form.Group className='mb-0'>
                <Form.Label className="mb-0">{order.vehicle ? "Náklady na dopravu" : "Cena pro dopravce"}</Form.Label>
                <InputGroup>
                    <Form.Control type="number" disabled={readOnly} value={getCurrentValue('price_carrier')} onChange={e => updateValues({ price_carrier: e.target.value })} />
                    <InputGroup.Append>
                        <Form.Control as="select" disabled={readOnly} custom value={getCurrentValue('carrier_currency')?.code} onChange={e => updateValues({ carrier_currency: getCurrencyByCode(e.target.value) })}>
                            {props.currencies.map((currency, key) => {
                                return <option key={key} value={currency.code}>{currency.label}</option>
                            })}
                        </Form.Control>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
            <Form.Group className='mb-1'>
                <Form.Label className='mb-0'>Příplatek zákazníka</Form.Label>
                <InputGroup>
                    <Form.Control type="number" disabled={readOnly} value={getCurrentValue('surcharge_customer')} onChange={e => updateValues({ surcharge_customer: e.target.value })} />
                    <InputGroup.Append>
                        <InputGroup.Text>
                            {getCurrentValue("customer_currency")?.label}
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
            <div className="d-flex justify-content-between align-items-center">
                <div>Marže</div>
                <div className={`text-right ${margin > 0 ? "text-success" : "text-danger"}`}>
                <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-margin">
                            {marginComputed && <>"Vypočítáno z cen, po uložení se může lišit." <br /></>}
                            {getCurrentValue('customer_currency')?.code === getCurrentValue('carrier_currency').code ? "Marže je vypočítána z cen v jedné měně." : <div>
                                    {`1 ${getCurrentValue('carrier_currency').code} = ${parseFloat(getCurrentValue('carrier_currency').exchange_rate).toFixed(2)} ${getCurrentValue('customer_currency').code}`}<br/>
                                    {`1 ${getCurrentValue('customer_currency').code} = ${ (1 / parseFloat(getCurrentValue('carrier_currency').exchange_rate)).toFixed(2) } ${getCurrentValue('carrier_currency').code}`}
                                </div>}
                        </Tooltip>}
                    >
                    <span>
                        {marginComputed && "~"} {margin ? margin.toFixed(2) : "?"} {getCurrentValue('customer_currency').label}
                    </span>
                </OverlayTrigger>
                </div>
            </div>
            <hr className="my-1" />
            {/* Load price */}
            <Form.Group className='mb-0'>
                <Form.Label className='mb-0'>Hodnota nákladu</Form.Label>
                <InputGroup>
                    <Form.Control type="number" disabled={readOnly} value={getCurrentValue('load_price')} onChange={e => updateValues({ load_price: e.target.value })} />
                    <InputGroup.Append>
                        <Form.Control as="select" disabled={readOnly} custom value={getCurrentValue('load_currency')?.code} onChange={e => updateValues({ load_currency: getCurrencyByCode(e.target.value) })}>
                            {props.currencies.map((currency, key) => {
                                return <option key={key} value={currency.code}>{currency.label}</option>
                            })}
                        </Form.Control>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Group>
            <hr className="my-1" />
            <Form.Group className='mb-0'>
                <Form.Label className="mb-0">Přiřazený uživatel</Form.Label>
                <UserSelector users={props.users} user={getCurrentValue('user')} userChanged={user => updateValues({user})} readOnly={readOnly} />
            </Form.Group>
            <hr className="my-1" />
            <Form.Group className='mb-0'>
                <Form.Label className="mb-0">Jazyk</Form.Label>
                <Form.Control as="select" custom value={getCurrentValue('language')} onChange={e => updateValues({ language: e.target.value })} disabled={readOnly}>
                    {languages.map((lang, key) => {
                        return <option key={key} value={lang.value}>{lang.display_name}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <hr className="my-2" />
            <Form.Group className='mb-0'>
                <Form.Label className="mb-0">Stav objednávky</Form.Label>
                <Form.Control as="select" custom value={getCurrentValue('state')} onChange={e => updateValues({ state: e.target.value })} disabled={readOnly}>
                    {state_choices.map((state, key) => {
                        return <option key={key} value={state.value}>{state.display_name}</option>
                    })}
                </Form.Control>
            </Form.Group>
            <hr className="my-2" />
            <div className='text-muted'>
                <strong>Vytvořeno:</strong><br />
                {new User(order.created_by).label()}<br />
                {new Date(order.created_at).toLocaleString()}
            </div>
            <Button variant="outline-secondary" onClick={() => props.collapse && props.collapse()}>
                <LayoutSidebar /> Skrýt boční panel
            </Button>
        </div>
    )
}

export default OrderEditorSidebar;